<script>
  import AuthorDetailsComponent from '@/components/authors/author-details-component/AuthorDetailsComponent.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  export default {
    name: 'author-details',
    data() {
      return {
        isFetchingCourses: false,
        coursesSuccessfullyFetched: false,
      };
    },
    components: {
      AuthorDetailsComponent,
    },
    computed: {
      ...mapGetters('AuthorsModule', ['authors']),
      author() {
        const authorId = this.$route.params.authorId;
        return this.authors[authorId];
      },
    },
    async created() {
      await this.fetchAllAuthors();
      await this.fetchCourses();
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      ...mapActions('CoursesModule', { fetchAllCourses: 'fetchAll' }),
      ...mapActions('AuthorsModule', { fetchAllAuthors: 'fetchAll' }),
      async fetchCourses() {
        this.coursesSuccessfullyFetched = false;
        this.isFetchingCourses = true;
        try {
          // Need to fetch all courses through which we can filter author's courses
          await this.fetchAllCourses();

          this.coursesSuccessfullyFetched = true;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingCourses = false;
        }
      },
    },
  };
</script>
