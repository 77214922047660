<template>
  <transition
    name="slide-left"
    appear
    mode="out-in">
    <div>
      <v-row
        justify="center"
        no-gutters>
        <!-- Yes, courses, not author. It's not a typo, see line 35 in mixin file for clarification -->

        <!-- courses-currently-being-fetched -->
        <div
          v-if="isFetchingCourses"
          class="my-12">
          <v-progress-linear
            indeterminate
            color="primaryColor"></v-progress-linear>
        </div>
        <!-- courses-fetched-successfully -->
        <author-details-component
          v-else-if="!isFetchingCourses && coursesSuccessfullyFetched"
          :author="author" />
      </v-row>
    </div>
  </transition>
</template>

<script>
  import AuthorDetailsMixin from '@/views/authors/author-details/AuthorDetails.mixin.vue';
  export default {
    mixins: [AuthorDetailsMixin],
  };
</script>
